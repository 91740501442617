import React, { useState } from 'react'
import WebAccountService from '../../../services/WebAccountService';
import Spinner from "../../../components/shared/Spinner";
import styles from "./forgotUser.module.scss";
import { information, arrows } from "../../../shared/icons";
import NotificationModal from '../../Members_page/Shared/NotificationModal';

interface Popup {
    title: string;
    message: string;
    success: boolean;
};

const ForgotUser = (props: any) => {
    const { setRenderComponent } = props;

    const [formRegisterValues, setFormRegisterValues] = useState({
        'roleId': ''
    });
    const [isLoad, setIsLoad] = useState<boolean>(false);
    const [validation, setValidation] = useState<boolean>(true);
    const [popup, setPopup] = useState<Popup | null>(null);

    const handleChange = (event: any) => {
        const { name, value } = event.target;
        setFormRegisterValues({ ...formRegisterValues, [name]: value })
    };

    const handleSubmit = async (event: any) => {
        const formData = { ...formRegisterValues };
        try {
            event.preventDefault();
            setIsLoad(true);
            const response = await WebAccountService.forgotUsername(
                { roleId: formData.roleId }
            );
            if (response.success) {
                setIsLoad(false);
                setValidation(false);
            } else {
                setIsLoad(false);
                setValidation(true);
                setPopup({
                    title: "Something went wrong",
                    message: response.message,
                    success: false
                });
            }
        } catch (event) {
            setIsLoad(false);
            setValidation(true);
        }
    };

    const handleComponent = (e: any, numb: number) => {
        e.preventDefault();
        setRenderComponent(numb)
    }

    return (
        <>
            {validation
                ? (<div className={styles.forms}>
                    <form onSubmit={handleSubmit} className={styles.form}>
                        <ul>
                            <div className={styles.backWrapper}>
                                <a href="#" onClick={(e) => handleComponent(e, 0)}>{arrows.left}Back to login</a>
                            </div>
                            <h2>Forgot username</h2>
                            <div className={styles.tooltipWrapper}>
                                <div className={styles.tooltip}>
                                    <p className={styles.tooltipText}>Enter the member ID associated with your account.
                                        <span className={styles.information}>
                                            {information}
                                            <span className={styles.tooltipItem}>Contact your club if you don't have your ID </span>
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <li>
                                <input
                                    placeholder='Member id or key tag'
                                    onChange={handleChange}
                                    name='roleId'
                                    value={formRegisterValues.roleId}
                                    type="text"
                                    id="roleId"
                                    className={styles.input}
                                />
                            </li>
                            <li>
                                <div>
                                    <button
                                        type="submit"
                                        className={styles.button}>
                                        Submit
                                    </button>
                                </div>
                            </li>
                        </ul>
                    </form>
                </div>) :
                (
                    <div className={styles.forms} >
                        <h2>Check your email</h2>
                        <p>We sent the username to your email address.</p>
                        <div className={styles.backWrapperValidation}>
                            <a href="#" onClick={(e) => handleComponent(e, 0)}>Back to login</a>
                        </div>
                    </div >
                )}
            {isLoad && <Spinner />}
            {popup &&
                <NotificationModal title={popup.title} message={popup.message} handleClose={() => setPopup(null)}>
                </NotificationModal>}
        </>
    )
}

export default ForgotUser;