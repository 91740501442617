import { navigate } from "gatsby";
import React, { ReactElement, useEffect, useState } from "react";
import PrismicService from "../../services/PrismicService";
import {
    getSeoDescription,
    getSeoKeywords,
    getSeoTitle,
    isWindow,
} from "../../shared/helpers";
import Login from "./login";
import Register from "./register";
import ForgotPass from "./forgotPass";
import ForgotUser from "./forgotUser";
import styles from "./RegisterAndLogin.module.scss";
import Helmet from "../../components/Helmet";
import useWindowWidth from "../../hooks/useWindowWidth";

const RegisterAndLogin = (props: any): ReactElement => {
    const [pageData, setPageData] = useState(null as any);
    const [renderComponent, setRenderComponent] = useState(0);

    const isDesktop = useWindowWidth(575);

    useEffect(() => {
        const getPrismicData = async () => {
            let prismicService;
            let registerAndLogin: any;

            await Promise.resolve(
                PrismicService.getInstance()
                    .then(val => {
                        prismicService = val;
                        return Promise.resolve(
                            prismicService.getDocuments(
                                "register_and_login",
                            ),
                        );
                    })
                    .then(val => (registerAndLogin = val)),
            );

            registerAndLogin.results.length
                ? setPageData(registerAndLogin.results[0].data)
                : " ";
        };
        getPrismicData();
    }, []);

    const handleComponent = (component: number) => {
        const switchComponent = {
            0: (
                <Login
                    pageData={pageData}
                    setRenderComponent={setRenderComponent}
                />
            ),
            1: <Register pageData={pageData} setRenderComponent={setRenderComponent} />,
            2: <ForgotPass setRenderComponent={setRenderComponent} />,
            3: <ForgotUser setRenderComponent={setRenderComponent} />,
        };
        return (
            switchComponent[component] ?? (
                <Login pageData={pageData} />
            )
        );
    };

    return (
        <>
            {isWindow && localStorage.getItem("jwt-token") ? (
                navigate("/members/dashboard")
            ) : (
                <>
                    <Helmet
                        title={getSeoTitle(
                            pageData,
                            "Register and Login",
                        )}
                        description={getSeoDescription(
                            pageData,
                            "Register and Login page",
                        )}
                        keywords={getSeoKeywords(pageData, "")}
                    />
                    <div className={styles.sectionsWrapper} style={{
                        backgroundImage: `url(${pageData?.form_image.url})`,
                    }}>
                        <div className={styles.rightSection}>
                            {handleComponent(renderComponent)}
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default RegisterAndLogin;
