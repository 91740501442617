import React, { useState, useEffect, PropsWithChildren } from 'react'
import WebAccountService from '../../../services/WebAccountService';
import Spinner from "../../../components/shared/Spinner";
import styles from "./createPass.module.scss";
import CustomCheckbox from '../../../components/shared/CustomCheckbox';
import { isWindow } from '../../../shared/helpers';
import { navigate } from 'gatsby-link';
import NotificationModal from '../../Members_page/Shared/NotificationModal';

interface Popup {
    title: string;
    message: string;
    success: boolean;
};

const CreatePass = (
    props: PropsWithChildren<any>) => {
    const [formRegisterValues, setFormRegisterValues] = useState({
        'password': '',
        'confirmPassword': '',
        'token': '',
        'policyAccepted': false
    });
    const [isLoad, setIsLoad] = useState<boolean>(false);
    const [popup, setPopup] = useState<Popup | null>(null);
    const [wrongPass, setWrongPass] = useState<boolean>(false)

    useEffect(() => {
        let url_string = isWindow ? window.location.href : "";
        let url = new URL(url_string);
        let token = url.searchParams.get("token");
        setFormRegisterValues({ ...formRegisterValues, token: token ? token : "" })
    }, [])

    const handleChange = (event: any) => {
        const { name, value } = event.target;
        setFormRegisterValues({ ...formRegisterValues, [name]: value })
    };

    const handleSubmit = async (event: any) => {
        const formData = { ...formRegisterValues };
        try {
            event.preventDefault();

            if (formData.password !== formData.confirmPassword) {
                setPopup({
                    title: "Verify the information",
                    message: "Passwords don't match",
                    success: false
                });

                return;
            }

            setIsLoad(true);
            const response = await WebAccountService.createPassword(
                {
                    token: formData.token,
                    password: formData.password
                }
            );
            setIsLoad(false);
            if (response.success) {
                setPopup({
                    title: "Success",
                    message: "You have successfully registered",
                    success: true
                });
            }
            else {
                setPopup({
                    title: "Something went wrong",
                    message: response.message,
                    success: false
                });
            }
        } catch (event) {
            setIsLoad(false);
        }
    };

    const handlePopUpClose = () => {
        if (popup?.success) {
            navigate("/members/login");
        }
        else {
            setPopup(null);
        }
    }

    useEffect(() => {
        if (formRegisterValues.confirmPassword !== formRegisterValues.password) {
            setWrongPass(true)
        }
        else {
            setWrongPass(false)
        }
    }, [formRegisterValues.confirmPassword])

    return (
        <>
            <div className={styles.forms} >
                <form onSubmit={handleSubmit}>
                    <ul>
                        <div className={styles.title}>
                            <h2>Create your password</h2>
                        </div>
                        <li>
                            <input
                                placeholder='password'
                                onChange={handleChange}
                                name="password"
                                value={formRegisterValues.password}
                                type="password"
                                id="password"
                                className={styles.input}
                            />
                            <label htmlFor="password">
                                password
                            </label>
                        </li>
                        <li>
                            <input
                                placeholder='confirm password'
                                onChange={handleChange}
                                name="confirmPassword"
                                value={formRegisterValues.confirmPassword}
                                type="password"
                                id="confirmPassword"
                                className={`${styles.input} ${wrongPass ? styles.wrong : ""}`}
                            />
                            <label htmlFor="confirmPassword">
                                confirm password
                            </label>
                            {wrongPass && <small className={styles.wrongPass}>Those passwords didn´t match. Try again.</small>}
                        </li>
                        <li>
                            <div className={styles.checkbox}>
                                <CustomCheckbox
                                    name={"policyAccepted"}
                                    label={""}
                                    handleChange={() => { setFormRegisterValues({ ...formRegisterValues, policyAccepted: !formRegisterValues.policyAccepted }) }} />
                                <small>By creating an account you agree with our <a href="#">Terms & Conditions</a> and <a href="#">Privacy Policy</a></small>
                            </div>
                        </li>
                        <li>
                            <button
                                disabled={(!formRegisterValues.password || !formRegisterValues.confirmPassword || !formRegisterValues.policyAccepted || wrongPass) ? true : false}
                                className={`${styles.button} ${(!formRegisterValues.password || !formRegisterValues.confirmPassword || !formRegisterValues.policyAccepted || wrongPass) ? styles.disabled : ""}`}
                            >
                                SUBMIT
                            </button>
                        </li>
                    </ul>
                </form >
                {popup &&
                    <NotificationModal title={popup.title} message={popup.message} handleClose={() => handlePopUpClose()}>
                    </NotificationModal>}
            </div >
            {isLoad && <Spinner />}
        </>
    )
}

export default CreatePass;